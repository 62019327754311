import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import isDeepEqual from 'fast-deep-equal/react'
import { Form, Divider, Row, Col, Button, Popover, Typography } from 'antd'
import {
  SearchOutlined,
  DeleteOutlined,
  WarningOutlined,
  SwapOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Field, Formik } from 'formik'

import TextField from '../../../shared/TextField'
import SelectField from '../../../shared/SelectField'
import CheckboxField from '../../../shared/CheckboxField'

import CollapsibleCard from './CollapsibleCard'
import CollapsibleCardProfile from './CollapsibleCardProfile'

import { searchSchema } from '../../../../schema/search'
import {
  removeEmptyValues,
  validateSearchParams,
} from '../../../../utils/functions'
import { stateOptions } from '../../../../data'

const initialSearchValues = {
  local_addresses_enabled: true,
  corporate_addresses_enabled: true,
  additional_addresses_enabled: true,
  local_contacts_enabled: true,
  corporate_contacts_enabled: true,
  legal_contacts_enabled: true,
  additional_contacts_enabled: true,
}

const initialFacilityProfileSearchValues = {
  auto_detect_main_retriever: 'all',
  auto_detect_copy_company: 'all',
  auto_detect_communication_method: 'all',
}

const renderHints = (suggestions = {}, name = '', setValue) => {
  if (!suggestions) {
    return null
  }

  const actions = Object.keys(suggestions)

  if (actions.length === 0) {
    return null
  }
  const contents = []

  actions.forEach(action => {
    const data = suggestions[action] || {}
    const hints = data[name]

    if (!hints || hints.length === 0) {
      return null
    }
    switch (action) {
      case 'replace':
        contents.push({
          title: 'You Can Try out Following',
          content: (
            <>
              <ul className="suggestion__list">
                {hints.map(option => (
                  <li
                    key={`${action}-${name}-${option}-`}
                    aria-hidden
                    className="suggestion__list-item"
                    onClick={() => setValue(name, option)}
                  >
                    <SwapOutlined className="suggestion__list-icon" />
                    <Typography.Link>{option}</Typography.Link>
                  </li>
                ))}
              </ul>
            </>
          ),
        })

        break
      case 'delete':
        contents.push({
          title:
            'There are Some Results if You Clear One or More Highlighted Fields',
          content: (
            <Button
              className="suggestion__button--delete"
              icon={<DeleteOutlined />}
              type="text"
              onClick={() => setValue(name, '')}
            >
              Clear Field
            </Button>
          ),
        })
        break
      default:
        return null
    }

    return null
  })

  if (contents.length === 0) {
    return null
  }

  return (
    <Popover
      content={contents.map(({ title, content }, index) => (
        <Fragment key={`suggestion-${title}-${index + 1}`}>
          <Typography.Title level={4}>{title}</Typography.Title>
          {content}
        </Fragment>
      ))}
      placement="bottom"
      overlayClassName="suggestion-popover"
    >
      <WarningOutlined className="suggestion__icon" />
    </Popover>
  )
}

const AddressHint = ({ form, suggestions, name, searchValue }) => {
  if (!suggestions) {
    return null
  }

  const actions = Object.keys(suggestions)
  if (actions.length === 0) {
    return null
  }

  const contents = []

  actions.forEach(action => {
    const data = suggestions[action] || {}
    const hints = data[name]
    const setAddressValues = index => {
      const addressFields = hints[index]
      Object.keys(addressFields).forEach(fieldName => {
        form.setFieldValue(fieldName, addressFields[fieldName])
      })
    }
    let formattedHints = hints.map(item => {
      const formattedArray = [
        item.address_line_1,
        item.address_line_2,
        item.state,
        item.city,
        item.zip_code,
      ].filter(option => option)
      return formattedArray.join(', ')
    })

    const formattedCurrentAddress = [
      searchValue?.address_line_1,
      searchValue?.address_line_2,
      searchValue?.state,
      searchValue?.city,
      searchValue?.zip_code,
    ]
      .filter(Boolean)
      .join(', ')

    formattedHints = [...new Set(formattedHints)].filter(
      item => item !== formattedCurrentAddress
    )
    if (!formattedHints || formattedHints.length === 0) {
      return null
    }

    switch (action) {
      case 'replace':
        contents.push({
          title: 'You Can Try out Following',
          content: (
            <>
              <ul className="suggestion__list">
                {formattedHints.map((option, index) => (
                  <li
                    key={`${action}-${name}-${option}-`}
                    aria-hidden
                    className="suggestion__list-item"
                    onClick={() => setAddressValues(index)}
                  >
                    <SwapOutlined className="suggestion__list-icon" />
                    <Typography.Link>{option}</Typography.Link>
                  </li>
                ))}
              </ul>
            </>
          ),
        })

        break
      default:
        return null
    }

    return null
  })
  if (contents.length === 0) {
    return null
  }
  return (
    <Popover
      content={contents.map(({ title, content }, index) => (
        <Fragment key={`suggestion-${title}-${index + 1}`}>
          <Typography.Title level={4}>{title}</Typography.Title>
          {content}
        </Fragment>
      ))}
      placement="bottom"
      overlayClassName="suggestion-popover"
    >
      <WarningOutlined className="suggestion__icon suggestion__icon__address" />
    </Popover>
  )
}

const SearchForm = ({
  onReset,
  onSubmit,
  initialValues,
  searchSuggestion,
  allowRevert,
  isFacilityProfile,
}) => {
  const formRef = useRef(null)
  const isOpen = !!initialValues?.gaia_id
  const [form] = Form.useForm()
  const initialValuesRef = useRef(initialValues)

  let initialSearchValue = { ...initialSearchValues }

  if (isFacilityProfile) {
    initialSearchValue = {
      ...initialSearchValue,
      ...initialFacilityProfileSearchValues,
    }
  }

  if (!isDeepEqual(initialValuesRef.current, initialValues)) {
    initialValuesRef.current = initialValues
  }

  const handleSubmitForm = useCallback(
    async values => {
      const validatedValues = validateSearchParams(values)
      Object.keys(initialFacilityProfileSearchValues).map(key => {
        if (validatedValues[key]) {
          validatedValues[key] = validatedValues[key] === 'true'
        }
        return key
      })
      await onSubmit(removeEmptyValues(validatedValues), values)
    },
    [onSubmit]
  )

  useEffect(() => {
    if (formRef?.current) {
      const values = { ...initialSearchValue, ...initialValuesRef.current }
      formRef.current.setValues(values)
    }
    // eslint-disable-next-line
  }, [formRef, initialValuesRef.current])

  return (
    <Formik
      onSubmit={handleSubmitForm}
      initialValues={{ ...initialSearchValue, ...initialValues }}
      validationSchema={searchSchema}
      innerRef={formRef}
    >
      {({
        handleSubmit,
        resetForm,
        isSubmitting,
        setFieldValue,
        initialValues: data,
        values,
      }) => {
        const handleResetForm = async () => {
          await resetForm({
            values: initialSearchValue,
          })
          await onReset(
            removeEmptyValues(validateSearchParams(initialSearchValue))
          )
        }

        const handleRevert = async () => {
          await resetForm({
            values: { ...initialValues, ...initialSearchValue },
          })
          await handleSubmit(removeEmptyValues(validateSearchParams(data)))
        }

        return (
          <Form
            onFinish={handleSubmit}
            layout="vertical"
            className="search-form"
            scrollToFirstError
            name="search"
            form={form}
          >
            <div className="search-form__card">
              <Field
                component={TextField}
                name="facility_name"
                placeholder="Type Here"
                label={
                  <>
                    Last Name
                    <Divider type="vertical" className="search-form__divider" />
                    Facility Name
                  </>
                }
                suffix={renderHints(
                  searchSuggestion,
                  'facility_name',
                  setFieldValue
                )}
              />
              <Field
                component={TextField}
                name="first_name"
                placeholder="Type Here"
                label="First Name"
                suffix={renderHints(
                  searchSuggestion,
                  'first_name',
                  setFieldValue
                )}
              />
            </div>
            <div className="search-form__card">
              <Row gutter={4}>
                <Col span={12}>
                  <Field
                    component={TextField}
                    name="phone"
                    placeholder="Type Here"
                    label="Phone"
                    suffix={renderHints(
                      searchSuggestion,
                      'phone',
                      setFieldValue
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    component={TextField}
                    name="fax"
                    placeholder="Type Here"
                    label="Fax"
                    suffix={renderHints(searchSuggestion, 'fax', setFieldValue)}
                  />
                </Col>
              </Row>

              <Form.Item valuePropName="checked">
                <Field
                  name="local_contacts_enabled"
                  label="Location"
                  component={CheckboxField}
                />
                <Field
                  name="corporate_contacts_enabled"
                  label="Corporate"
                  component={CheckboxField}
                />
                <Field
                  name="legal_contacts_enabled"
                  label="Legal"
                  component={CheckboxField}
                />
                <Field
                  name="additional_contacts_enabled"
                  label="Additional"
                  component={CheckboxField}
                />
              </Form.Item>
            </div>
            <div className="search-form__card">
              <Field
                component={TextField}
                name="address_line_1"
                placeholder="Type Here"
                label="Address Line 1"
                suffix={renderHints(
                  searchSuggestion,
                  'address_line_1',
                  setFieldValue
                )}
              />
              <Row gutter={4}>
                <Col span={12}>
                  <Field
                    component={TextField}
                    name="address_line_2"
                    placeholder="Type Here"
                    label="Address Line 2"
                    suffix={renderHints(
                      searchSuggestion,
                      'address_line_2',
                      setFieldValue
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    component={TextField}
                    name="zip_code"
                    placeholder="Type Here"
                    label="Zip code"
                    suffix={renderHints(
                      searchSuggestion,
                      'zip_code',
                      setFieldValue
                    )}
                  />
                </Col>
              </Row>

              <Row gutter={4}>
                <Col span={12}>
                  <Field
                    component={TextField}
                    name="city"
                    placeholder="Type Here"
                    label="City"
                    suffix={renderHints(
                      searchSuggestion,
                      'city',
                      setFieldValue
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    component={SelectField}
                    name="state"
                    placeholder="Select Here"
                    label="State"
                    options={stateOptions}
                    showSearch
                    allowClear
                    optionFilterProp="label"
                    suffix={renderHints(
                      searchSuggestion,
                      'state',
                      setFieldValue
                    )}
                    filterOption={(input, option) => {
                      const optionByName = option.value
                        .toLowerCase()
                        .includes(input.toLowerCase())

                      const optionByTwoLetter = option.label
                        .toLowerCase()
                        .includes(input.toLowerCase())

                      return optionByName || optionByTwoLetter
                    }}
                  />
                </Col>
              </Row>
              <Form.Item valuePropName="checked">
                <Field
                  name="local_addresses_enabled"
                  label="Location"
                  component={CheckboxField}
                />
                <Field
                  name="corporate_addresses_enabled"
                  label="Corporate"
                  component={CheckboxField}
                />
                <Field
                  name="additional_addresses_enabled"
                  label="Additional"
                  component={CheckboxField}
                />
                <AddressHint
                  className="address-hint"
                  form={formRef.current}
                  suggestions={searchSuggestion}
                  searchValue={initialValues}
                  name="address"
                />
              </Form.Item>
            </div>
            <CollapsibleCard
              renderHints={renderHints}
              searchSuggestion={searchSuggestion}
              onSetFieldValue={setFieldValue}
              isOpen={isOpen}
            />
            <Row justify="center" gutter={[16, 24]}>
              {allowRevert && (
                <Col span={12}>
                  <Button
                    icon={<SyncOutlined className="search-form__button-icon" />}
                    block
                    size="large"
                    onClick={handleRevert}
                  >
                    Revert Criteria
                  </Button>
                </Col>
              )}
            </Row>
            {isFacilityProfile && (
              <CollapsibleCardProfile
                onSetFieldValue={setFieldValue}
                data={data}
                values={values}
              />
            )}

            <Row justify="space-between">
              <Col span={12}>
                <Button
                  type="text"
                  icon={<DeleteOutlined className="search-form__button-icon" />}
                  onClick={handleResetForm}
                  size="large"
                >
                  Clear Search
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block
                  icon={<SearchOutlined className="search-form__button-icon" />}
                  loading={isSubmitting}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SearchForm

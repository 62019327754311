import { object, number } from 'yup'

export const organizationsSchema = object().shape({
  retriever_cms_id: number().when('womba', womba => {
    if (womba === 'ON' || womba === 'SERVICE TYPE ONLY') {
      return number()
        .integer('Must include only digits')
        .typeError('Must include only digits')
        .required('Please enter Retriever CMS ID')
    }

    return number().typeError('Must include only digits')
  }),
  cms_aps_supervisor_id: number()
    .integer('Must include only digits')
    .typeError('Must include only digits')
    .nullable(),
  cms_odr_supervisor_id: number()
    .integer('Must include only digits')
    .typeError('Must include only digits')
    .nullable(),
})

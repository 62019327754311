import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import AgenciesService from '../../../services/AgenciesService'

export const getAgencies = createAsyncThunk(
  'agencies/getAgencyList',
  async data => {
    const response = await getPagination(
      data,
      AgenciesService.getAgencyListing.bind(AgenciesService)
    )

    return { ...response }
  }
)

export const fetchAgencyById = createAsyncThunk(
  'agencies/fetchAgencyById',
  async id => {
    const response = await AgenciesService.getAgencyById(id)

    return response
  }
)

export const updateAgency = createAsyncThunk(
  'agencies/updateAgency',
  async ({ id, data }) => {
    const response = await AgenciesService.update(id, data)

    return response
  }
)

export const resetState = createAction('agencies/resetState')

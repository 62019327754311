import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import InsuranceCompaniesService from '../../../services/InsuranceCompaniesService'

export const getInsuranceCompanies = createAsyncThunk(
  'insuranceCompanies/getInsuranceCompanyList',
  async data => {
    const response = await getPagination(
      data,
      InsuranceCompaniesService.getInsuranceCompanyListing.bind(
        InsuranceCompaniesService
      )
    )

    return { ...response }
  }
)

export const fetchInsuranceCompanyById = createAsyncThunk(
  'insuranceCompanies/fetchInsuranceCompanyById',
  async id => {
    const response = await InsuranceCompaniesService.getInsuranceCompanyById(id)

    return response
  }
)

export const updateInsuranceCompany = createAsyncThunk(
  'insuranceCompanies/updateInsuranceCompany',
  async ({ id, data }) => {
    const response = await InsuranceCompaniesService.update(id, data)

    return response
  }
)

export const resetState = createAction('insuranceCompany/resetState')

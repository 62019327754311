import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Field } from 'formik'
import {
  Col,
  Form,
  Input,
  Row,
  Spin,
  Tabs as Tab,
  Button,
  Tooltip,
  Modal,
  Typography,
  Divider,
  Select,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'

import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import Tabs from '../../components/Tabs'
import TextField from '../../shared/TextField'
import { wombaOptions } from '../../../data/selectOptions'

import {
  getInsuranceCompanies,
  selectAllInsuranceCompanies,
  updateInsuranceCompany,
  resetState as resetInsuranceCompaniesState,
} from '../../../state/modules/insuranceCompanies'
import {
  getAgencies,
  selectAllAgencies,
  resetState as resetAgenciesState,
  updateAgency,
} from '../../../state/modules/agencies'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import { INSURANCE_COMPANY, AGENCIES } from '../../../constants'
import useDebounce from '../../../hooks/useDebounce'
import { organizationsSchema } from '../../../schema/organizations'
import { useUser } from '../../../providers/UserProvider'
import { validateSearchParams } from '../../../utils/functions'

const Organizations = () => {
  const { isUserActionAllowed } = useUser()
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [wombaData, setWombaData] = useState('')
  const [retrieverId, setRetrieverId] = useState('')
  const [cmsApsSupervisorId, setCmsApsSupervisorId] = useState('')
  const [cmsOdrSupervisorId, setCmsOdrSupervisorId] = useState('')
  const [organizationId, setOrganizationId] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [searchParams, setSearchParams] = useState({})
  const insuranceCompaniesList = useSelector(selectAllInsuranceCompanies)
  const agenciesList = useSelector(selectAllAgencies)
  const { count: insuranceCompaniesCount } = useSelector(
    state => state.insuranceCompanies
  )
  const { count: agencyCount } = useSelector(state => state.agencies)
  const loadingStatus = useSelector(state => state.insuranceCompanies.status)
  const [organizationPagination, setOrganizationPagination] = useState({
    page: 1,
    pageSize: 10,
  })

  const [isRetrieverDisabled, setIsRetrieverDisabled] = useState(false)

  const formInitialValues = {
    womba: wombaData,
    retriever_cms_id: retrieverId,
    cms_aps_supervisor_id: cmsApsSupervisorId,
    cms_odr_supervisor_id: cmsOdrSupervisorId,
    isRetrieverDisabled,
  }

  const tabs = [
    {
      name: 'Insurance Companies',
      key: 0,
      getData: getInsuranceCompanies,
      count: insuranceCompaniesCount,
      totalData: insuranceCompaniesList,
      entity: INSURANCE_COMPANY,
      update: updateInsuranceCompany,
      permission: 'view_insurancecompany',
      editPermission: 'change_insurancecompany',
    },
    {
      name: 'Agencies',
      key: 1,
      getData: getAgencies,
      count: agencyCount,
      totalData: agenciesList,
      entity: AGENCIES,
      update: updateAgency,
      permission: 'view_agency',
      editPermission: 'change_agency',
    },
  ]
  const allowedTabs = tabs
    .filter(item => isUserActionAllowed(item.permission) || !item.permission)
    .map((item, index) => {
      item.key = index
      return item
    })

  const handleOpenModal = e => {
    if (e) e.preventDefault()
    setIsModalVisible(!isModalVisible)
  }

  const handleChangeSearch = useDebounce(e => {
    const { value } = e.target
    setSearchParams({ ...searchParams, search: value.trim() })
    dispatchHttp(
      allowedTabs[activeTab]?.getData({
        ...validateSearchParams(searchParams),
        search: value.trim(),
      })
    )
  }, 500)

  const handleChangeWombaFilter = state => {
    setSearchParams({ ...searchParams, womba: state })

    dispatchHttp(
      allowedTabs[activeTab]?.getData({
        ...searchParams,
        ...validateSearchParams({ womba: state }),
      })
    )
  }

  const handleUpdateOrganization = async ({ id, data }) =>
    dispatchHttp(
      allowedTabs[activeTab]?.update({ id, data }),
      'Womba updated successfully'
    )

  const onSubmit = async ({ id, data }) => {
    const {
      retriever_cms_id,
      cms_aps_supervisor_id,
      cms_odr_supervisor_id,
    } = data
    let apsId = {}
    let odrId = {}
    let retrieverCmsId = {}

    const { isRetrieverDisabled: formFlag, ...changedFields } = Object.keys(
      data
    ).reduce((acc, key) => {
      if (data[key] !== formInitialValues[key]) {
        acc[key] = data[key]
      }
      return acc
    }, {})

    // Send aps and odr only if was changed
    // If is empty send null
    if ('cms_aps_supervisor_id' in changedFields) {
      apsId = cms_aps_supervisor_id
        ? { cms_aps_supervisor_id }
        : { cms_aps_supervisor_id: null }
    }
    if ('cms_odr_supervisor_id' in changedFields) {
      odrId = cms_odr_supervisor_id
        ? { cms_odr_supervisor_id }
        : { cms_odr_supervisor_id: null }
    }
    if ('retriever_cms_id' in changedFields) {
      retrieverCmsId = retriever_cms_id
        ? { retriever_cms_id }
        : { retriever_cms_id: null }
    }

    const updatedData = {
      ...changedFields,
      ...apsId,
      ...odrId,
      ...retrieverCmsId,
    }

    await handleUpdateOrganization({ id, data: updatedData })
    return dispatchHttp(
      allowedTabs[activeTab]?.getData({
        ...organizationPagination,
        ...searchParams,
      })
    )
  }

  const setModalData = text => {
    const retrieverCMSId = text.retriever ? text.retriever.cms_id : ''

    if (
      text.womba !== 'ON' &&
      text.womba !== 'SERVICE TYPE ONLY' &&
      text.womba !== 'TARGETED'
    ) {
      setIsRetrieverDisabled(true)
    } else {
      setIsRetrieverDisabled(false)
    }

    setModalTitle(`Edit ${text.name}`)
    setWombaData(text.womba)
    setRetrieverId(retrieverCMSId)
    setCmsApsSupervisorId(text.cms_aps_supervisor_id || '')
    setCmsOdrSupervisorId(text.cms_odr_supervisor_id || '')
    setOrganizationId(text.id)
  }

  const renderRetrieverId = text =>
    text ? (
      text.cms_id
    ) : (
      <Typography.Text type="secondary">No info</Typography.Text>
    )

  const renderRetrieverColumn = text => (
    <>
      {(() => {
        if (!text?.retriever) {
          return <Typography.Text type="secondary">No info</Typography.Text>
        }

        return (
          <>
            {text?.retriever?.fullname ? (
              <span>
                {text?.retriever?.fullname}
                <Divider
                  type="vertical"
                  className="organization-name__divider"
                />
              </span>
            ) : null}
            {text?.retriever?.contname}
          </>
        )
      })()}
    </>
  )

  const renderEditColumn = text => (
    <>
      <Button
        type="text"
        onClick={() => {
          setModalData(text)
          handleOpenModal()
        }}
        icon={
          <Tooltip title="Edit">
            <EditOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />
          </Tooltip>
        }
      />
    </>
  )

  const organizationsTableColumns = [
    {
      title: 'Name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Department',
      key: 'department',
      align: 'center',
      width: 150,
    },
    {
      title: 'CMS ID',
      key: 'cms_id',
      align: 'center',
      width: 100,
    },
    {
      title: 'Womba',
      key: 'womba',
      align: 'center',
      width: 100,
      render: text => {
        const wombaOption = wombaOptions.find(option => option.value === text)
        return wombaOption ? wombaOption.label : text
      },
    },
    {
      title: 'Retriever CMS ID',
      key: 'retriever',
      align: 'center',
      width: 100,
      render: text => renderRetrieverId(text),
    },
    {
      title: 'Retriever',
      key: 'retriever',
      align: 'center',
      width: 170,
      render: (_, col, text) => renderRetrieverColumn(text),
    },
    {
      title: 'APS CMS Supervisor ID',
      key: 'cms_aps_supervisor_id',
      align: 'center',
      width: 86,
    },
    {
      title: 'ODR Supervisor CMS ID',
      key: 'cms_odr_supervisor_id',
      align: 'center',
      width: 86,
    },
  ]

  const editColumn = {
    title: '',
    key: 'id',
    align: 'center',
    width: 50,
    render: (_, col, text) => renderEditColumn(text),
  }

  const handleWombaChange = (wombaOption, values, setValues) => {
    if (
      wombaOption !== 'ON' &&
      wombaOption !== 'SERVICE TYPE ONLY' &&
      wombaOption !== 'TARGETED'
    ) {
      setValues(
        {
          ...values,
          isRetrieverDisabled: true,
          retriever_cms_id: '',
          womba: wombaOption,
        },
        true
      )
    } else {
      setValues(
        {
          ...values,
          isRetrieverDisabled: false,
          retriever_cms_id: '',
          womba: wombaOption,
        },
        true
      )
    }
  }

  const getColumns = () => {
    const columnsCustom = [...organizationsTableColumns]

    if (isUserActionAllowed(allowedTabs[activeTab]?.editPermission)) {
      columnsCustom.push(editColumn)
    }

    return columnsCustom
  }

  useEffect(() => {
    if (!allowedTabs[activeTab]) return
    form?.resetFields()
    dispatchHttp(allowedTabs[activeTab]?.getData({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp, activeTab])

  useEffect(() => {
    setOrganizationPagination({ page: 1, pageSize: 10 })
  }, [insuranceCompaniesCount, agencyCount])

  useEffect(
    () => () => {
      dispatch(resetInsuranceCompaniesState())
      dispatch(resetAgenciesState())
    },
    [dispatch]
  )

  return (
    <Spin spinning={false}>
      <PageLayout
        title={
          <>
            Organizations
            <span className="badge">{allowedTabs[activeTab]?.count}</span>
          </>
        }
      >
        <Modal
          visible={isModalVisible}
          footer={null}
          closable={false}
          centered
          destroyOnClose
          onCancel={handleOpenModal}
          maskStyle={{
            background: 'rgba(0, 0, 0, 0.6)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <Typography.Title level={3}>{modalTitle}</Typography.Title>
          <Formik
            initialValues={formInitialValues}
            validationSchema={organizationsSchema}
            onSubmit={async (...data) => {
              try {
                await onSubmit({ data: data[0], id: organizationId })
                handleOpenModal()
              } catch (err) {
                /* empty */
              }
            }}
            enableReinitialize
          >
            {({ handleSubmit, values, setValues, initialValues, dirty }) => (
              <Form
                initialValues={initialValues}
                onFinish={handleSubmit}
                className="organization-form"
                layout="vertical"
              >
                <Row gutter={10}>
                  <Col span={12}>
                    <Field
                      label="Womba"
                      size="medium"
                      name="womba"
                      placeholder="Select Here"
                      options={wombaOptions}
                    >
                      {() => (
                        <Form.Item label="Womba">
                          <Select
                            defaultValue={initialValues.womba}
                            name="womba"
                            options={wombaOptions}
                            onChange={e => {
                              handleWombaChange(e, values, setValues)
                            }}
                          />
                        </Form.Item>
                      )}
                    </Field>
                  </Col>
                  <Col span={12}>
                    <Field
                      component={TextField}
                      label="Retriever CMS ID"
                      size="medium"
                      name="retriever_cms_id"
                      disabled={values.isRetrieverDisabled}
                    />
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={12}>
                    <Field
                      component={TextField}
                      label="APS CMS Supervisor ID"
                      size="medium"
                      name="cms_aps_supervisor_id"
                    />
                  </Col>
                  <Col span={12}>
                    <Field
                      component={TextField}
                      label="ODR CMS Supervisor ID"
                      size="medium"
                      name="cms_odr_supervisor_id"
                    />
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ paddingTop: '10px' }}
                >
                  <Col>
                    <Button onClick={handleOpenModal}>Close window</Button>
                  </Col>
                  <Col>
                    <Button type="primary" htmlType="submit" disabled={!dirty}>
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal>
        <Tabs
          fullWidth
          theme="dark"
          tabsProps={{
            size: 'small',
            tabBarGutter: 24,
            onChange: key => {
              setSearchParams({ ...searchParams, search: '', womba: 'all' })
              setActiveTab(+key)
            },
            activeKey: `${activeTab}`,
          }}
        >
          {allowedTabs.map(({ key, name }) => (
            <Tab.TabPane tab={name} key={key}>
              <Row
                gutter={[16, 32]}
                style={{
                  background: '#fff',
                  padding: '14px 12px',
                  justifyContent: 'flex-end',
                  margin: 0,
                }}
              >
                <Form
                  form={form}
                  layout="inline"
                  className="sort-form"
                  component="div"
                  initialValues={{ search: '', womba: 'all' }}
                  size="small"
                >
                  <Row>
                    <Col>
                      <Form.Item label="Womba" name="womba" size="small">
                        <Select
                          style={{ width: 150 }}
                          onChange={handleChangeWombaFilter}
                        >
                          <Select.Option value="all">All</Select.Option>
                          {wombaOptions.map(option => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="Search" name="search" size="small">
                        <Input
                          onChange={handleChangeSearch}
                          type="text"
                          className="search-input"
                          placeholder="Name, Department, CMS ID or Retriever CMS ID"
                          style={{ width: 300 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Row>
              <Table
                columns={getColumns()}
                onLoadData={allowedTabs[activeTab]?.getData}
                formData={validateSearchParams(searchParams)}
                totalCount={allowedTabs[activeTab]?.count}
                totalData={allowedTabs[activeTab]?.totalData}
                loadingStatus={loadingStatus}
                entity={allowedTabs[activeTab]?.entity}
                onPageChange={setOrganizationPagination}
              />
            </Tab.TabPane>
          ))}
        </Tabs>
      </PageLayout>
    </Spin>
  )
}

export default Organizations
